.modal-body {
  padding: 0 !important;
}

.payment-body {
  min-height: 100vh;
  background-color: #FFF;
  overflow-x: hidden;

  .bg-left {
    background: transparent;
  }

  .payment-side {
    width: 100%;
    padding: 0;

    .card {
      background: #f3f3f3;
      border-radius: 10px;
    }

    .tab-content {
      margin: 1rem;

      .momo,
      .card-element {
        border-radius: 10px;
      }
    }

    .plendify_pay {
      // width: 50%;
    }

    .notes {
      font-size: 12px;
    }
  }
}

.preloader {
  background-color: rgba(255, 255, 255, 0.44);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  -webkit-transition: .6s;
  -o-transition: .6s;
  transition: .6s;
  margin: 0 auto;

  .preloader-circle {
    width: 100px;
    height: 100px;
    position: relative;
    border-style: solid;
    border-width: 3px;
    border-top-color: $primary;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    z-index: 10;
    border-radius: 50%;
    -webkit-box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
    box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
    background-color: #ffffff;
    -webkit-animation: zoom 2000ms infinite ease;
    animation: zoom 2000ms infinite ease;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
  }

  .preloader-img {
    position: absolute;
    top: 50%;
    z-index: 200;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-top: 6px;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    -webkit-animation: fade 500ms;
    animation: fade 500ms;    

    img {
      max-width: 55px;
    }
  }

  &.hide {
    -webkit-animation: disappear 500ms;
    animation: disappear 500ms;
    display: none !important;
  }
}

.processing {
  background: linear-gradient(to left, #FFF 50%, #6a1b9a 50%) right;
  background-size: 200%;
  transition: .5s ease-out;

  &.animate {
    background-position: left;
    transition-delay: 0.5s;
  }
}

.nav-pills .nav-link {
  color: #555;
  padding: 8px 2px !important;

  img {
    filter: invert(31%) sepia(23%) saturate(0%) hue-rotate(214deg) brightness(92%) contrast(82%);
  }
}

.nav-pills .nav-link.active {
  color: #6a1b9a;

  img {
    filter: none !important;
  }
}

input[type="radio"] {
  margin-right: 5px
}

.pay_option {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  padding-bottom: 0!important;
}

/* Customize the label (the container) */
.radio-inline {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 36%;
    bottom: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;

    /* Create the indicator (the dot/circle - hidden when not checked) */
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  /* On mouse-over, add a grey background color */
  &:hover input~.checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  & input:checked~.checkmark {
    background-color: #6a1b9a;
  }

}

/* Show the indicator (dot/circle) when checked */
.radio-inline input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-inline .checkmark:after {
  left: 7px;
  top: 4px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.swal2-confirm {
  background-color: $primary !important;
}

// Custom Styling
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  scrollbar-width: thin;
}

::-webkit-scrollbar-thumb,
.member--info::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #c8baba;
  margin: 0 1px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

.fade {
  transition: 0.5s;
  -webkit-animation: fade 0.5s;
  animation: fade 0.5s;    
}