.payment-body {
    overflow-x: hidden;
    height: 100vh;

    .bg-left {
        background-image: linear-gradient($primary, rgba(106, 27, 154, 0.72));
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }    
    
    .payment-side {
        width: 80%;
        padding: 2rem 1rem;

        .tab-content {
            margin: 0;
        }        

        .plendify_pay {
            width: auto!important;
        }        
    }
}

.pay_option {
    padding-right: 0 !important;
    padding-left: 0 !important;
}