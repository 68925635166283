body {
    margin: 0;
    background: #f5f5f5;
    font-family: AvenirBook, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  
  
  @font-face {
    font-family: "AvenirBook";
    src: url("../fonts/avenir/AvenirLTStd-Book.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility; 
  }
  @font-face {
    font-family: "AvenirBlack";
    src: url("../fonts/avenir/AvenirLTStd-Black.otf") format("opentype");
    font-style: normal;
    font-weight: bolder;
    text-rendering: optimizeLegibility; 
  }
  
  .AvenirBook {
    font-family: 'AvenirBook'!important;
  }
  .AvenirBlack {
    font-family: 'AvenirBlack'!important;
  }
  