// Override default variables before the import
$body-bg: #000;
$primary: #6a1b9a;

// colors
$red: #da3d2a;
$green: #44ab65;
$white: #ffffff;
$default: #000000;
$pale-grey: #fafbfc;
$gray: #F2F2F2;
$light-periwinkle: #dfe2e6;
$secondary: #FF0000;

$colors: () !default;

$colors: map-merge(
  (
    "secondary": $secondary,
    "red": $red,
    "green": $green,
    "white": $white,
    "default": $default,
    "pale-grey": $pale-grey,
    "light-periwinkle": $light-periwinkle,
  ),
  $colors
);

// grids
$grids: (
  (
    name: "sm",
    grid: 0,
  ),
  (
    name: "md",
    grid: 768,
  ),
  (
    name: "lg",
    grid: 992,
  ),
  (
    name: "xl",
    grid: 1200,
  )
);

// font weights
$weights: (400, 500, 600, 700, 800);

:root {
  --red: #da3d2a;
  --green: #44ab65;
  --white: #ffffff;
  --default: #000000;
  --pale-grey: #fafbfc;
  --light-periwinkle: #dfe2e6;
}


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';