@keyframes zoom {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

@keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@keyframes disappear {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}
